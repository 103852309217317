import { USERS_LOG } from "../../actions/usersLogAction";

const initialState = {
    getListUsersLog: false,
    getListUsersLogError: false,

    allPage: 1
}

const usersLog = (state = initialState, action) => {
    switch (action.type) {
        case USERS_LOG:
            return {
                ...state,
                getListUsersLog: action.payload.data,
                allPage: action.payload.page,
                getListUsersLogError: action.payload.errorMessage
            }
        default:
            return state
    }
}

export default usersLog