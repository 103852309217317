import { DELETE_APP, GET_APP, GET_LIST_APPS, OPTION_APPS_BYGROUP, POST_APP, PUT_APP, RESET_APP, SEARCH_APPS } from "../../actions/appsAction"

const initialState = {
    getListAppsResult: false,
    getListAppsError: false,

    getAppResult: false,
    getAppError: false,

    postAppResult: false,
    postAppError: false,

    putAppResult: false,
    putAppError: false,

    deleteAppResult: false,
    deleteAppError: false,

    optionAppsbyGroupResult: false,
    optionAppsbyGroupError: false,

    allPage: 1
}

const apps = (state = initialState, action) => {
    switch(action.type){
        case GET_LIST_APPS:
            return {
                ...state,
                getListAppsResult: action.payload.data,
                allPage: action.payload.page,
                getListAppsError: action.payload.errorMessage
            }
        case SEARCH_APPS:
            return {
                ...state,
                getListAppsResult: action.payload.data,
                allPage: action.payload.page,
                getListAppsError: action.payload.errorMessage
            }
        case GET_APP:
            return {
                ...state,
                getAppResult: action.payload.data,
                getAppError: action.payload.errorMessage
            }
        case POST_APP:
            return {
                ...state,
                postAppResult: action.payload.data,
                postAppError: action.payload.errorMessage
            }
        case PUT_APP:
            return {
                ...state,
                putAppResult: action.payload.data,
                putAppError: action.payload.errorMessage
            }
        case DELETE_APP:
            return {
                ...state,
                deleteAppResult: action.payload.data,
                deleteAppError: action.payload.errorMessage
            }
        case OPTION_APPS_BYGROUP:
            return {
                ...state,
                optionAppsbyGroupResult: action.payload.data,
                optionAppsbyGroupError: action.payload.errorMessage
            }
        case RESET_APP:
            return {
                ...state,
                getAppResult: false,
                getAppError: false,
                optionAppsbyGroupResult: [],
                optionAppsbyGroupError: false
            }
        default:
            return state
    }
}

export default apps