import { SET_SIDEBAR_SHOW, SET_ASIDE_SHOW, SET_THEME, SET_UNFOLDABLE, SET_OPTION_SHOW } from "../../actions/displayAction"

const initialState = {
    unfoldable: false,
    sidebarShow: true,
    asideShow: false,
    optionShow: false,
    theme: 'default'
  }
  
const changeState = (state = initialState, action) => {
    switch (action.type) {
      case SET_SIDEBAR_SHOW:
        return { 
            ...state,
            sidebarShow: action.payload.data 
         }
      case SET_ASIDE_SHOW:
        return {
            ...state,
            asideShow: action.payload.data
         }
      case SET_THEME:
        return {
            ...state,
            theme: action.payload.data
         }
      case SET_UNFOLDABLE:
        return {
            ...state,
            unfoldable: action.payload.data
         }
      case SET_OPTION_SHOW:
        return {
            ...state,
            optionShow: action.payload.data
         }
      default:
        return state
    }
}

export default changeState