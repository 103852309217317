import axios from "axios";
import Swal from "sweetalert2";

import { BASE_URL } from "./helper";

export const GET_APP_PERMISSION = "GET_APP_PERMISSION"
export const POST_APP_PERMISSION = "POST_APP_PERMISSION"
export const RESET_PERMISSION_RESULT = "RESET_PERMISSION_RESULT"

export const getAppPermission = (group_id, app_id, token) => {
    return (dispatch) => {
        // post API
        axios.get(BASE_URL + 'permission-details',
        {
            headers: {
               Authorization : 'Bearer ' + token
            },
            params: {
                app_id: app_id,
                group_id: group_id
            }
        }
        )
        .then((response) => {
            let data = []
            let resp = response.data.data

            resp.map((item) => {
                if(item.permission_id){
                    return data.push({
                        "permission_id" : item.permission_id,
                        "module_id" : item.module_id,
                        "module_name" : item.module_name,
                        "iscreate" : item.iscreate,
                        "isread" : item.isread,
                        "isupdate" : item.isupdate,
                        "isdelete" : item.isdelete,
                        "isprint" : item.isprint
                    })
                }
                return data.push({
                    "module_id" : item.module_id,
                    "module_name" : item.module_name,
                    "iscreate" : false,
                    "isread" : false,
                    "isupdate" : false,
                    "isdelete" : false,
                    "isprint" : false
                })
            })
                dispatch({
                    type: GET_APP_PERMISSION,
                    payload: {
                        data: data,
                        errorMessage: false
                    }
                })
        })
        .catch((err) => {
            
            if(!err.response.data){
                Swal.fire(
                    'Error!',
                    'Server Doesn`t Response',
                    'error'
                )
                return 
            }
            if(err.response.status == 401){
                document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
                window.location.href = '/jj-admin'
                return
            }
            Swal.fire(
                'Error!',
                err.response?.data?.message,
                'error'
            )
            dispatch({
                type: GET_APP_PERMISSION,
                payload: {
                    data: false,
                    errorMessage: err.response
                }
            })
        })
    }
}

export const postAppPermission = (form, token) => {
    return (dispatch) => {
        //get API
        axios.post(BASE_URL + 'permission-details', form,{
            headers: {
               Authorization : 'Bearer ' + token
            }
        })
        .then((response) => {
            dispatch({
                type: POST_APP_PERMISSION,
                payload: {
                    data: response.data,
                    errorMessage: false
                }
            })
            Swal.fire({
                title: 'Success!',
                text: response.data.message,
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
              })
        })
        .catch((err) => {
            
            if(!err.response.data){
                Swal.fire(
                    'Error!',
                    'Server Doesn`t Response',
                    'error'
                )
                return 
            }
            if(err.response.status == 401){
                document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
                window.location.href = '/jj-admin'
                return
            }
            Swal.fire(
                'Error!',
                err.response?.data?.message,
                'error'
            )
            dispatch({
                type: POST_APP_PERMISSION,
                payload: {
                    data: false,
                    errorMessage: err.response
                }
            })
        })
    }
}

export const resetPermissionResult = () => {
    return (dispatch) => {
       dispatch({
        type: RESET_PERMISSION_RESULT,
       })
    }
}