import axios from "axios";
import Swal from "sweetalert2";
import { setOptionShow } from "./displayAction";

import { BASE_URL } from "./helper";


export const GROUP_APPS = "GROUP_APP"
export const OPTION_APPS = "OPTION_APPS"
export const POST_MAPPING = "POST_MAPPING"
export const DELETE_MAPPING = "DELETE_MAPPING"
export const RESET_MAPPING = "RESET_MAPPING"


export const getMappingApps = (id, token) => {
    return (dispatch) => {
        

        const req = axios.create({
            headers: {
                Authorization : 'Bearer ' + token
            }
        })

        const appAll = req.get(BASE_URL + 'apps/all')
        const appByGroup = req.get(BASE_URL + 'apps/byGroup', {
            params: {
                group_id: id
            }
        })


        axios.all([
            appAll,
            appByGroup
        ])
        .then((response)=>{
            var app_all = response[0].data.data
            var app_byGroup = response[1].data.data
            let option = []
            
            if(app_byGroup.length){
                for(let i = 0; i < app_byGroup.length; i++){
                    for (let j = 0; j < app_all.length; j++){
                        if(app_byGroup[i].app_id === app_all[j].app_id){
                            app_all.splice(j, 1)
                        }
                    }
                }
            }

            dispatch({
                    type: GROUP_APPS,
                    payload: {
                        data: app_byGroup,
                        errorMessage: false
                    }
                    })
            
            for(let item of app_all){
                option.push({
                    value : item.app_id,
                    text: item.app_name,
                })
            }
            dispatch({
                    type: OPTION_APPS,
                    payload: {
                        data: option,
                        errorMessage: false
                    }
                    })


        })
        .catch((err) => {
            
            if(!err.response.data){
                Swal.fire(
                    'Error!',
                    'Server Doesn`t Response',
                    'error'
                )
                return 
            }
            if(err.response.status == 401){
                document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
                window.location.href = '/jj-admin'
                return
            }
            Swal.fire(
                'Error!',
                err.response?.data?.message,
                'error'
            )
            dispatch({
                type: OPTION_APPS,
                payload: {
                    data: false,
                    errorMessage: err.response
                }
            })
        })
    }
}

export const createGroupMapping = (form, token) => {
    return (dispatch) => {
        const id = form.group_id
        // post API
        axios.post(BASE_URL + 'group-permission', form,
        {
            headers: {
               Authorization : 'Bearer ' + token
            }
        }
        )
        .then((response) => {
                dispatch({
                    type: POST_MAPPING,
                    payload: {
                        data: response.data,
                        errorMessage: false
                    }
                })
                Swal.fire(
                    'Success!',
                    response.data.message,
                    'success'
                  ).then((result) => {
                    if(result.isConfirmed){
                        dispatch(getMappingApps(id, token))
                        setTimeout(() => {
                            dispatch(setOptionShow(true))
                          }, 1000)
                    }
                  })
        })
        .catch((err) => {
            
            if(!err.response.data){
                Swal.fire(
                    'Error!',
                    'Server Doesn`t Response',
                    'error'
                )
                return 
            }
            if(err.response.status == 401){
                document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
                window.location.href = '/jj-admin'
                return
            }
            Swal.fire(
                'Error!',
                err.response?.data?.message,
                'error'
            )
            dispatch({
                type: POST_MAPPING,
                payload: {
                    data: false,
                    errorMessage: err.response
                }
            })
        })
    }
}

export const deleteGroupMapping = (group_id, app_id, token) => {
    return (dispatch) => {
        //get API
        axios.delete(BASE_URL + 'group-permission', {
            headers: {
               Authorization : 'Bearer ' + token
            },
            params: {
                group_id: group_id,
                app_id: app_id
            }
        })
        .then((response) => {
            dispatch({
                type: DELETE_MAPPING,
                payload: {
                    data: response.data,
                    errorMessage: false
                }
            })
            Swal.fire(
                'Deleted!',
                response.data.message,
                'success'
              )
              .then((result) => {
                if(result.isConfirmed){
                    dispatch(getMappingApps(group_id, token))
                    setTimeout(() => {
                        dispatch(setOptionShow(true))
                      }, 1000)
                }
              })
        })
        .catch((err) => {
            
            if(!err.response.data){
                Swal.fire(
                    'Error!',
                    'Server Doesn`t Response',
                    'error'
                )
                return 
            }
            if(err.response.status == 401){
                document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
                window.location.href = '/jj-admin'
                return
            }
            Swal.fire(
                'Error!',
                err.response?.data?.message,
                'error'
            )
            dispatch({
                type: DELETE_MAPPING,
                payload: {
                    data: false,
                    errorMessage: err.response
                }
            })
        })
    }
}

export const resetMapping = () => {
    return (dispatch) => {
        dispatch({
            type: RESET_MAPPING
        })
    }
}