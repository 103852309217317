import { combineReducers } from "redux"
import dashboardReducer from './dashboard'
import usersReducer from './users'
import groupsReducer from './groups'
import appsReducer from './apps'
import groupMappingReducer from './groupMapping'
import appPermissionReducer from './appPermission'
import reportReducer from './report'
import usersLogReducer from './usersLog'
import changeState from "./display"

export default combineReducers({
    dashboardReducer,
    usersReducer,
    groupsReducer,
    appsReducer,
    groupMappingReducer,
    appPermissionReducer,
    reportReducer,
    usersLogReducer,
    changeState,
})