import { GET_DATA_DASHBOARD } from "../../actions/dashboardAction"

const initialState = {
    totalUserActive: false,
    totalUserPending: false,
    totalApps: false,
    totalGroups: false,
    chartLabel: false,
    chartData: false,
    errorData: false,
}

const dashboard = (state = initialState, action) => {
    switch(action.type){
        case GET_DATA_DASHBOARD:
            return {
                ...state,
                totalUserActive: action.payload.active,
                totalUserPending: action.payload.pending,
                totalApps: action.payload.apps,
                totalGroups: action.payload.groups,
                chartLabel: action.payload.chartlabel,
                chartData: action.payload.chartdata,
                errorData: action.payload.errorMessage
            }
        default:
            return state
    }
}

export default dashboard