import axios from "axios";
import Swal from "sweetalert2";

import { BASE_URL } from "./helper";

export const GET_LIST_APPS = "GET_LIST_APPS"
export const SEARCH_APPS = "SEARCH_APPS"
export const GET_APP = "GET_APP"
export const POST_APP = "POST_APP"
export const PUT_APP = "PUT_APP"
export const DELETE_APP = "DELETE_APP"
export const OPTION_APPS_BYGROUP = "OPTION_APPS_BYGROUP"
export const RESET_APP = "RESET_APP"

export const getListApps = (perPage, currentPage, token) => {
    return (dispatch) => {
        
        //get API
        axios.get(BASE_URL + 'apps', {
            headers: {
               Authorization : 'Bearer ' + token
            },
            params: {
                take: perPage,
                page: currentPage
            }
        })
        .then((response) => {
            let data = response.data.data.data
            let page = response.data.data.lastPage
            dispatch({
                type: GET_LIST_APPS,
                payload: {
                    data: data,
                    page: page,
                    errorMessage: false
                }
               
            })
        })
        .catch((err) => {
            
            if(!err.response.data){
                Swal.fire(
                    'Error!',
                    'Server Doesn`t Response',
                    'error'
                )
                return 
            }
            if(err.response.status == 401){
                document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
                window.location.href = '/jj-admin'
                return
            }
            Swal.fire(
                'Error!',
                err.response?.data?.message,
                'error'
            )
            dispatch({
                type: GET_LIST_APPS,
                payload: {
                    data: false,
                    errorMessage: err.response
                }
            })
        })
    }
}

export const getSearchApps = (keyword, token) => {
    return (dispatch) => {
        
        //get API
        axios.get(BASE_URL + 'apps', {
            headers: {
               Authorization : 'Bearer ' + token,
            },
            params: {
                keyword
            }
        })
        .then((response) => {
            let avail = response.data.data.data.length
            let data = response.data.data.data
            let page = response.data.data.lastPage

            if(!avail){
                dispatch({
                    type: SEARCH_APPS,
                    payload: {
                        data: [],
                        page: 1,
                        errorMessage: false
                    }
                })
                return
            }
            dispatch({
                type: SEARCH_APPS,
                payload: {
                    data: data,
                    page: page,
                    errorMessage: false
                }
            })
            
        })
        .catch((err) => {
            
            if(!err.response.data){
                Swal.fire(
                    'Error!',
                    'Server Doesn`t Response',
                    'error'
                )
                return 
            }
            if(err.response.status == 401){
                document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
                window.location.href = '/jj-admin'
                return
            }
            Swal.fire(
                'Error!',
                err.response?.data?.message,
                'error'
            )
            dispatch({
                type: SEARCH_APPS,
                payload: {
                    data: false,
                    errorMessage: err.response
                }
            })
        })
    }
}

export const getOneApp = (id, token) => {
    return (dispatch) => {
        
        //get API
        axios.get(BASE_URL + 'apps/module', {
            headers: {
               Authorization : 'Bearer ' + token
            },
            params: {
                app_id: id
            }

        })
        .then((response) => {

            let info = response.data.data.data[0]
            dispatch({
                type: GET_APP,
                payload: {
                    data: info,
                    errorMessage: false
                }
               
            })
        })
        .catch((err) => {
            
            if(!err.response.data){
                Swal.fire(
                    'Error!',
                    'Server Doesn`t Response',
                    'error'
                )
                return 
            }
            if(err.response.status == 401){
                document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
                window.location.href = '/jj-admin'
                return
            }
            Swal.fire(
                'Error!',
                err.response?.data?.message,
                'error'
            )
            dispatch({
                type: GET_APP,
                payload: {
                    data: false,
                    errorMessage: err.response
                }
            })
        })
    }
}



export const getOptionAppsbyGroup = (id, token) => {
    return(dispatch) => {
        
        axios.get(BASE_URL + 'apps/byGroup', {
            headers: {
               Authorization : 'Bearer ' + token
            },
            params: {
                group_id : id
            }
        })
        .then((response) => {
            let optionApp = ['Select the App']
            for(let item of response.data.data){
                optionApp.push({
                    value: item.app_id,
                    label: item.app_name
                })
            }
            dispatch({
                type: OPTION_APPS_BYGROUP,
                payload: {
                    data: optionApp,
                    errorMessage: false
                }
            })
        })
        .catch((err) => {
            
            if(!err.response.data){
                Swal.fire(
                    'Error!',
                    'Server Doesn`t Response',
                    'error'
                )
                return 
            }
            if(err.response.status == 401){
                document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
                window.location.href = '/jj-admin'
                return
            }
            Swal.fire(
                'Error!',
                err.response?.data?.message,
                'error'
            )
            dispatch({
                type: OPTION_APPS_BYGROUP,
                payload: {
                    data: false,
                    errorMessage: err.response
                }
            })
        })
    }
}

export const addApp = (form, token) => {
    return (dispatch) => {
        // post API
        axios.post(BASE_URL + 'apps', form,
        {
            headers: {
               Authorization : 'Bearer ' + token
            }
        }
        )
        .then((response) => {
            if(response.status == 201 && response.data.success == 1){
                dispatch({
                    type: POST_APP,
                    payload: {
                        data: response.data,
                        errorMessage: false
                    }
                })
                Swal.fire({
                    title: 'Success!',
                    text: response.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500
                  })
            }
        })
        .catch((err) => {
            
            if(!err.response.data){
                Swal.fire(
                    'Error!',
                    'Server Doesn`t Response',
                    'error'
                )
                return 
            }
            if(err.response.status == 401){
                document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
                window.location.href = '/jj-admin'
                return
            }
            Swal.fire(
                'Error!',
                err.response?.data?.message,
                'error'
            )
            dispatch({
                type: POST_APP,
                payload: {
                    data: false,
                    errorMessage: err.response
                }
            })
        })
    }
}

export const editApp = (id, form, token) => {
    return (dispatch) => {
        // post API
        axios.put(BASE_URL + 'apps/' + id, form,
        {
            headers: {
               Authorization : 'Bearer ' + token
            }
        }
        )
        .then((response) => {

            if(response.status == 200 && response.data.success == 1){
                dispatch({
                    type: PUT_APP,
                    payload: {
                        data: response.data,
                        errorMessage: false
                    }
                })
                Swal.fire({
                    title: 'Success!',
                    text: response.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500
                  })
            }
        })
        .catch((err) => {
            
            if(!err.response.data){
                Swal.fire(
                    'Error!',
                    'Server Doesn`t Response',
                    'error'
                )
                return 
            }
            if(err.response.status == 401){
                document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
                window.location.href = '/jj-admin'
                return
            }
            Swal.fire(
                'Error!',
                err.response?.data?.message,
                'error'
            )
            dispatch({
                type: PUT_APP,
                payload: {
                    data: false,
                    errorMessage: err.response
                }
            })
        })
    }
}


export const deleteApp = (id, token) => {
    return (dispatch) => {
        //get API
        axios.delete(BASE_URL + 'apps/', {
            headers: {
               Authorization : 'Bearer ' + token
            },
            params: {
                app_id : id
            }
        })
        .then((response) => {
            dispatch({
                type: DELETE_APP,
                payload: {
                    data: response.data,
                    errorMessage: false
                }
            })
            Swal.fire({
                title: 'Deleted!',
                text: response.data.message,
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
              })
        })
        .catch((err) => {
            
            if(!err.response.data){
                Swal.fire(
                    'Error!',
                    'Server Doesn`t Response',
                    'error'
                )
                return 
            }
            if(err.response.status == 401){
                document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
                window.location.href = '/jj-admin'
                return
            }
            Swal.fire(
                'Error!',
                err.response?.data?.message,
                'error'
            )
            dispatch({
                type: DELETE_APP,
                payload: {
                    data: false,
                    errorMessage: err.response
                }
            })
        })
    }
}

export const resetApp = () => {
    return (dispatch) => {
        dispatch({
            type: RESET_APP
        })
    }
}