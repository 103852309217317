import axios from "axios";
import Swal from "sweetalert2";

import { BASE_URL } from "./helper";

export const REPORT = "REPORT"

export const getReport = (perPage, currentPage, token) => {
    return (dispatch) => {
        
        axios.get(BASE_URL + 'users/reporting', {
            headers: {
                Authorization : 'Bearer ' + token
            },
            params: {
                take: perPage,
                page: currentPage
            }
        })
        .then((response) => {
            dispatch({
                type: REPORT,
                payload: {
                    data: response.data.data.data,
                    page: response.data.data.lastPage,
                    errorMessage: false
                }
            })
        })
        .catch((err) => {
            
            if(!err.response.data){
                Swal.fire(
                    'Error!',
                    'Server Doesn`t Response',
                    'error'
                )
                return 
            }
            if(err.response.status == 401){
                document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
                window.location.href = '/jj-admin'
                return
            }
            Swal.fire(
                'Error!',
                err.response?.data?.message,
                'error'
            )
            dispatch({
                type: REPORT,
                payload: {
                    data: false,
                    errorMessage: err.response
                }
            })
        })

    }
}