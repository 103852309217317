import axios from "axios";
import Swal from "sweetalert2";

import { BASE_URL } from "./helper";

export const GET_LIST_GROUPS = "GET_LIST_GROUPS"
export const SEARCH_GROUPS = "SEARCH_GROUPS"
export const GET_GROUP = "GET_GROUP"
export const POST_GROUP = "POST_GROUP"
export const PUT_GROUP = "PUT_GROUP"
export const DELETE_GROUP = "DELETE_GROUP"
export const SELECT_OPTION_GROUPS = "SELECT_OPTION_GROUPS"
export const MULTI_OPTION_GROUPS = "MULTI_OPTION_GROUPS"

export const getListGroups = (perPage, currentPage, token) => {
    return (dispatch) => {
        
        //get API
        axios.get(BASE_URL + 'groups', {
            headers: {
               Authorization : 'Bearer ' + token
            },
            params: {
                take: perPage,
                page: currentPage
            }
        })
        .then((response) => {
            let data = response.data.groupData.data
            let page = response.data.groupData.lastPage
            dispatch({
                type: GET_LIST_GROUPS,
                payload: {
                    data: data,
                    page: page,
                    errorMessage: false
                }
               
            })
        })
        .catch((err) => {
            
            if(!err.response.data){
                Swal.fire(
                    'Error!',
                    'Server Doesn`t Response',
                    'error'
                )
                return 
            }
            if(err.response.status == 401){
                document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
                window.location.href = '/jj-admin'
                return
            }
            Swal.fire(
                'Error!',
                err.response?.data?.message,
                'error'
            )
            dispatch({
                type: GET_LIST_GROUPS,
                payload: {
                    data: false,
                    errorMessage: err.response
                }
            })
        })
    }
}

export const getSearchGroups = (keyword, token) => {
    return (dispatch) => {
        
        //get API
        axios.get(BASE_URL + 'groups', {
            headers: {
               Authorization : 'Bearer ' + token,
            },
            params: {
                keyword
            }
        })
        .then((response) => {
            let avail = response.data.groupData.data.length
            let data = response.data.groupData.data
            let page = response.data.groupData.lastPage

            if(!avail){
                dispatch({
                    type: SEARCH_GROUPS,
                    payload: {
                        data: [],
                        page: 1,
                        errorMessage: false
                    }
                })
                return
            }
            dispatch({
                type: SEARCH_GROUPS,
                payload: {
                    data: data,
                    page: page,
                    errorMessage: false
                }
            })
        })
        .catch((err) => {
            
            if(!err.response.data){
                Swal.fire(
                    'Error!',
                    'Server Doesn`t Response',
                    'error'
                )
                return 
            }
            if(err.response.status == 401){
                document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
                window.location.href = '/jj-admin'
                return
            }
            Swal.fire(
                'Error!',
                err.response?.data?.message,
                'error'
            )
            dispatch({
                type: SEARCH_GROUPS,
                payload: {
                    data: false,
                    errorMessage: err.response
                }
            })
        })
    }
}

export const getOneGroup = (id, token) => {
    return (dispatch) => {
        
        //get API
        axios.get(BASE_URL + 'groups/' + id, {
            headers: {
               Authorization : 'Bearer ' + token
            }
        })
        .then((response) => {
            dispatch({
                type: GET_GROUP,
                payload: {
                    data: response.data.groupData,
                    errorMessage: false
                }
               
            })
        })
        .catch((err) => {
            
            if(!err.response.data){
                Swal.fire(
                    'Error!',
                    'Server Doesn`t Response',
                    'error'
                )
                return 
            }
            if(err.response.status == 401){
                document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
                window.location.href = '/jj-admin'
                return
            }
            Swal.fire(
                'Error!',
                err.response?.data?.message,
                'error'
            )
            dispatch({
                type: GET_GROUP,
                payload: {
                    data: false,
                    errorMessage: err.response
                }
            })
        })
    }
}

export const multiOptionGroups = (token) => {
    return (dispatch) => {
        
        //get API
        axios.get(BASE_URL + 'groups/all', {
            headers: {
               Authorization : 'Bearer ' + token
            }
        })
        .then((response) => {
            let option = []
            for(let item of  response.data.groupData){
                option.push({
                    value : item.group_id,
                    text: item.group_name,
                })
            }
            dispatch({
                type: MULTI_OPTION_GROUPS,
                payload: {
                    data: option,
                    errorMessage: false
                }
            })
        })
        .catch((err) => {
            
            if(!err.response.data){
                Swal.fire(
                    'Error!',
                    'Server Doesn`t Response',
                    'error'
                )
                return 
            }
            if(err.response.status == 401){
                document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
                window.location.href = '/jj-admin'
                return
            }
            Swal.fire(
                'Error!',
                err.response?.data?.message,
                'error'
            )
            dispatch({
                type: MULTI_OPTION_GROUPS,
                payload: {
                    data: false,
                    errorMessage: err.response
                }
            })
        })
    }
}

export const selectOptionGroups = (token) => {
    return (dispatch) => {
        
        //get API
        axios.get(BASE_URL + 'groups/all', {
            headers: {
               Authorization : 'Bearer ' + token
            }
        })
        .then((response) => {
            let option = ['Select Group']
            for(let item of  response.data.groupData){
                option.push({
                    value : item.group_id,
                    label: item.group_name,
                })
            }
            dispatch({
                type: SELECT_OPTION_GROUPS,
                payload: {
                    data: option,
                    errorMessage: false
                }
            })
        })
        .catch((err) => {
            
            if(!err.response.data){
                Swal.fire(
                    'Error!',
                    'Server Doesn`t Response',
                    'error'
                )
                return 
            }
            if(err.response.status == 401){
                document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
                window.location.href = '/jj-admin'
                return
            }
            Swal.fire(
                'Error!',
                err.response?.data?.message,
                'error'
            )
            dispatch({
                type: SELECT_OPTION_GROUPS,
                payload: {
                    data: false,
                    errorMessage: err.response
                }
            })
        })
    }
}

export const createGroup = (form, token) => {
    return (dispatch) => {
        // post API
        axios.post(BASE_URL + 'groups', form,
        {
            headers: {
               Authorization : 'Bearer ' + token
            }
        }
        )
        .then((response) => {
            if(response.status == 201 && response.data.success == 1){
                dispatch({
                    type: POST_GROUP,
                    payload: {
                        data: response.data,
                        errorMessage: false
                    }
                })
                Swal.fire({
                    title: 'Success!',
                    text: response.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500
                  })
            }
        })
        .catch((err) => {
            
            if(!err.response.data){
                Swal.fire(
                    'Error!',
                    'Server Doesn`t Response',
                    'error'
                )
                return 
            }
            if(err.response.status == 401){
                document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
                window.location.href = '/jj-admin'
                return
            }
            Swal.fire(
                'Error!',
                err.response?.data?.message,
                'error'
            )
            dispatch({
                type: POST_GROUP,
                payload: {
                    data: false,
                    errorMessage: err.response
                }
            })
        })
    }
}

export const updateGroup = (id, form, token) => {
    return (dispatch) => {
        // post API
        axios.put(BASE_URL + 'groups/' + id, form,
        {
            headers: {
               Authorization : 'Bearer ' + token
            }
        }
        )
        .then((response) => {
                dispatch({
                    type: PUT_GROUP,
                    payload: {
                        data: response.data,
                        errorMessage: false
                    }
                })
                Swal.fire({
                    title: 'Success!',
                    text: response.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500
                  })
        })
        .catch((err) => {
            
            if(!err.response.data){
                Swal.fire(
                    'Error!',
                    'Server Doesn`t Response',
                    'error'
                )
                return 
            }
            if(err.response.status == 401){
                document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
                window.location.href = '/jj-admin'
                return
            }
            Swal.fire(
                'Error!',
                err.response?.data?.message,
                'error'
            )
            dispatch({
                type: PUT_GROUP,
                payload: {
                    data: false,
                    errorMessage: err.response
                }
            })
        })
    }
}

export const deleteGroup = (id, token) => {
    return (dispatch) => {
        //get API
        axios.delete(BASE_URL + 'groups/' + id, {
            headers: {
               Authorization : 'Bearer ' + token
            }
        })
        .then((response) => {
            dispatch({
                type: DELETE_GROUP,
                payload: {
                    data: response.data,
                    errorMessage: false
                }
            })
            Swal.fire({
                title: 'Deleted!',
                text: response.data.message,
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
              })
        })
        .catch((err) => {
            
            if(!err.response.data){
                Swal.fire(
                    'Error!',
                    'Server Doesn`t Response',
                    'error'
                )
                return 
            }
            if(err.response.status == 401){
                document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
                window.location.href = '/jj-admin'
                return
            }
            Swal.fire(
                'Error!',
                err.response?.data?.message,
                'error'
            )
            dispatch({
                type: DELETE_GROUP,
                payload: {
                    data: false,
                    errorMessage: err.response
                }
            })
        })
    }
}