import { GET_APP_PERMISSION, POST_APP_PERMISSION, RESET_PERMISSION_RESULT } from "../../actions/appPermissionAction"

const initialState = {
    getAppPermissionResult: false,
    getAppPermissionError: false,

    postAppPermissionResult: false,
    postAppPermissionError: false,
}

const appPermission = (state = initialState, action) => {
    switch(action.type){
        case GET_APP_PERMISSION:
            return {
                ...state,
                getAppPermissionResult: action.payload.data,
                getAppPermissionError: action.payload.data,

            }
        case POST_APP_PERMISSION:
            return {
                ...state,
                postAppPermissionResult: action.payload.data,
                postAppPermissionError: action.payload.errorMessage
            }
        case RESET_PERMISSION_RESULT:
            return {
                ...state,
                getAppPermissionResult: [],
                getAppPermissionError: false,
            }
        default:
            return state
    }
}

export default appPermission