import { DELETE_USER, GET_LIST_USERS, PUT_USER, POST_USER, ONE_USER, ADD_OPTION_GROUPS, RESET_ONE_USER, SEARCH_USERS, LOG_USER } from "../../actions/usersAction";

const initialState = {
    getListUsersResult: false,
    getListUsersError: false,

    oneUserResult: false,
    oneUserError: false,
    
    logUserResult: false,
    logUserError: false,

    postUserResult: false,
    postUserError: false,

    putUserResult: false,
    putUserError: false,

    deleteUserResult: false,
    deleteUserError: false,

    addOptionGroupsResult: false,
    addOptionGroupsError: false,

    allPage: 1
}

const users = (state = initialState, action) => {
    switch(action.type){
        case GET_LIST_USERS:
            return {
                ...state,
                getListUsersResult: action.payload.data,
                allPage: action.payload.page,
                getListUsersError: action.payload.errorMessage
            }
        case SEARCH_USERS:
            return {
                ...state,
                getListUsersResult: action.payload.data,
                allPage: action.payload.page,
                getListUsersError: action.payload.errorMessage
            }
        case ONE_USER:
            return {
                ...state,
                oneUserResult: action.payload.data,
                oneUserError: action.payload.errorMessage
            }
        case LOG_USER:
            return {
                ...state,
                logUserResult: action.payload.data,
                logUserError: action.payload.errorMessage
            }
        case POST_USER:
            return {
                ...state,
                postUserResult: action.payload.data,
                postUserError: action.payload.errorMessage
            }
        case PUT_USER:
            return {
                ...state,
                putUserResult: action.payload.data,
                putUserError: action.payload.errorMessage
            }
        case DELETE_USER:
            return {
                ...state,
                deleteUserResult: action.payload.data,
                deleteUserError: action.payload.errorMessage
            }
        case ADD_OPTION_GROUPS:
            return {
                ...state,
                addOptionGroupsResult: action.payload.data,
                addOptionGroupsError: action.payload.errorMessage
            }
        case RESET_ONE_USER:
            return {
                ...state,
                addOptionGroupsResult: false,
                addOptionGroupsError: false,
                oneUserResult: false,
                oneUserError: false
            }
        default:
            return state
    }
}

export default users