import axios from "axios"
import Swal from "sweetalert2"
import { BASE_URL } from "./helper"

export const GET_DATA_DASHBOARD = "GET_DATA_DASHBOARD"

export const getDataDashboard = (token) => {
    return (dispatch) => {
        //get API

        const req = axios.create({
            headers: {
                Authorization : 'Bearer ' + token
            }
        })

        const users = req.get(BASE_URL + 'users/analytics')
        const apps = req.get(BASE_URL + 'apps/total')
        const groups = req.get(BASE_URL + 'groups/total')
        const chart = req.get(BASE_URL + 'users/chart')

        axios.all([
            users,
            apps,
            groups,
            chart
        ])
        .then((response) => {
            let totalActive = response[0].data?.data[0]?.total
            let totalPending = response[0].data?.data[1]?.total
            let totalApps = response[1].data?.data
            let totalGroups = response[2].data?.data
            let usersChart = response[3].data?.data
            let usersChartLabel = []
            let usersChartData = []


            usersChart.map((item) => {
                usersChartLabel.push(item.month)
                usersChartData.push(item.total)
            })

            dispatch({
                type: GET_DATA_DASHBOARD,
                payload: {
                    active: totalActive ? totalActive : 0,
                    pending: totalPending ? totalPending : 0,
                    apps: totalApps ? totalApps : 0,
                    groups: totalGroups ? totalGroups : 0,
                    chartlabel: usersChartLabel,
                    chartdata: usersChartData,
                    errorMessage: false
                }
            })
        })
        .catch((err) => {
            if(!err.response.data){
                Swal.fire(
                    'Error!',
                    'Server Doesn`t Response',
                    'error'
                )
                return 
            }
            if(err.response.status == 401){
                document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
                window.location.href = '/jj-admin'
                return 
            }
            Swal.fire(
                'Error!',
                err.response?.data?.message,
                'error'
            )
            dispatch({
                type: GET_DATA_DASHBOARD,
                payload: {
                    active: false,
                    pending: false,
                    apps: false,
                    groups: false,
                    errorMessage: err.response
                }
            })
        })
    }
}