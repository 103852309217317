import { DELETE_GROUP, GET_LIST_GROUPS, MULTI_OPTION_GROUPS, SELECT_OPTION_GROUPS, POST_GROUP, GET_GROUP, PUT_GROUP, SEARCH_GROUPS } from "../../actions/groupsAction"

const initialState = {
    getListGroupsResult: false,
    getListGroupsError: false,

    getGroupResult:false,
    getGroupError:false,

    postGroupResult: false,
    postGroupError: false,

    putGroupResult: false,
    putGroupError: false,

    deleteGroupResult: false,
    deleteGroupError: false,

    multiOptionGroupsResult: false,
    multiOptionGroupsError: false,

    selectOptionGroupsResult: false,
    selectOptionGroupsError: false,

    allPage: 1
}

const groups = (state = initialState, action) => {
    switch(action.type){
        case GET_LIST_GROUPS:
            return {
                ...state,
                getListGroupsResult: action.payload.data,
                allPage: action.payload.page,
                getListGroupsError: action.payload.errorMessage
            }
        case SEARCH_GROUPS:
            return {
                ...state,
                getListGroupsResult: action.payload.data,
                allPage: action.payload.page,
                getListGroupsError: action.payload.errorMessage
            }
        case GET_GROUP:
            return {
                ...state,
                getGroupResult: action.payload.data,
                getGroupError: action.payload.errorMessage
            }
        case DELETE_GROUP:
            return {
                ...state,
                deleteGroupResult: action.payload.data,
                deleteGroupError: action.payload.errorMessage
            }
        case POST_GROUP:
            return {
                ...state,
                postGroupResult: action.payload.data,
                postGroupError: action.payload.errorMessage
            }
        case PUT_GROUP:
            return {
                ...state,
                putGroupResult: action.payload.data,
                putGroupError: action.payload.errorMessage
            }
        case MULTI_OPTION_GROUPS:
            return {
                ...state,
                multiOptionGroupsResult: action.payload.data,
                multiOptionGroupsError: action.payload.errorMessage
            }
        case SELECT_OPTION_GROUPS:
            return {
                ...state,
                selectOptionGroupsResult: action.payload.data,
                selectOptionGroupsError: action.payload.errorMessage
            }
        default:
            return state
    }
}

export default groups