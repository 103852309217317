export const SET_THEME = "SET_THEME"
export const SET_ASIDE_SHOW = "SET_ASIDE_SHOW"
export const SET_SIDEBAR_SHOW = "SET_SIDEBAR_SHOW"
export const SET_OPTION_SHOW = "SET_OPTION_SHOW"
export const SET_UNFOLDABLE = "SET_UNFOLDABLE"

export const setUnfoldable = (data) => {
    return (dispatch) => {
        
        //get API
            dispatch({
                type: SET_UNFOLDABLE,
                payload: {
                    data: data,
                }
            })
    }
}

export const setTheme = (data) => {
    return (dispatch) => {
        //get API
            dispatch({
                type: SET_THEME,
                payload: {
                    data: data,
                }
            })
    }
}

export const setAsideShow = (data) => {
    return (dispatch) => {
        
        //get API
            dispatch({
                type: SET_ASIDE_SHOW,
                payload: {
                    data: data,
                }
            })
    }
}

export const setSidebarShow = (data) => {
    return (dispatch) => {
        
        //get API
            dispatch({
                type: SET_SIDEBAR_SHOW,
                payload: {
                    data: data,
                }
            })
    }
}

export const setOptionShow = (data) => {
    return (dispatch) => {
        
        //get API
            dispatch({
                type: SET_OPTION_SHOW,
                payload: {
                    data: data,
                }
            })
    }
}
