import { OPTION_APPS, GROUP_APPS, POST_MAPPING, DELETE_MAPPING, RESET_MAPPING } from "../../actions/groupMappingAction"

const initialState = {
    optionAppsResult: false,
    optionAppsError: false,

    postGroupMappingResult: false,
    postGroupMappingError: false,

    deleteGroupMappingResult: false,
    deleteGroupMappingError: false,

    groupAppsResult: false,
    groupAppsError: false,
}

const groupMapping = (state = initialState, action) => {
    switch(action.type){
        case OPTION_APPS:
            return {
                ...state,
                optionAppsResult: action.payload.data,
                optionAppsError: action.payload.errorMessage
            }
        case GROUP_APPS:
            return {
                ...state,
                groupAppsResult: action.payload.data,
                groupAppsError: action.payload.errorMessage
            }
        case POST_MAPPING:
            return {
                ...state,
                postGroupMappingResult: action.payload.data,
                postGroupMappingError: action.payload.errorMessage
            }
        case DELETE_MAPPING:
            return {
                ...state,
                deleteGroupMappingResult: action.payload.data,
                deleteGroupMappingError: action.payload.errorMessage
            }
        case RESET_MAPPING:
            return {
                ...state,
                optionAppsResult: false,
                optionAppsError: false,
                groupAppsResult: false,
                groupAppsError: false
            }
        default:
            return state
    }
}

export default groupMapping