import { REPORT } from "../../actions/reportAction"

const initialState = {
    getListReportResult: false,
    getListReportError: false,

    allPage: 1
}

const report = (state = initialState, action) => {
    switch (action.type) {
        case REPORT:
            return {
                ...state,
                getListReportResult: action.payload.data,
                allPage: action.payload.page,
                getListReportError: action.payload.errorMessage
            }
        default:
            return state
    }
}

export default report